// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;

  // padding: 24px 0px;
  .f-top {
    .wrap {
      .logo-img {
        .logo {
          height: 36px;

          @include media("<=phoneH") {
            height: 30px;
          }
        }
      }

      .social {
        .icon {
          height: 34px;
          width: 34px;
          padding: 8px;
          border-radius: 50%;
          background: #000;
          cursor: pointer;

          svg {
            path {
              fill: #fff;
            }
          }

          &:hover {
            svg {
              path {
                fill: #fceb00;
              }
            }
          }
        }
      }
    }
  }
}