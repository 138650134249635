.lading-page {
  width: 100%;
  height: 100%;

  .wrap {}



  .swiper-pagination {
    right: 60px !important;
  }
}

.hero-section {
  // height: 600px;
  // min-height: 100vh;
  min-height: calc(100vh - 0px);
  width: 100%;

  .home-slider {
    height: 100%;
    width: 100%;
    background-color: $themeColor;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .swiper {
    height: 100vh;
  }

  .overlay {}
}

.about-us-section {
  padding: 80px 0;

  .wrap {
    .btn-learn-more {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
}

.services-section {
  min-height: 530px;
  padding: 80px 0;

  .wrap {}
}

.agents-section {
  min-height: 530px;
  padding: 80px 0;

  .wrap {}
}

.tenants-section {
  min-height: 530px;
  padding: 80px 0;

  .wrap {}
}

.contact-us-section {
  min-height: 530px;
  padding: 80px 0;

  .wrap {
    ._block {
      border-radius: 12px;
      border: 1.5px solid $themeColor;
      overflow: hidden;

      .bg-img {
        height: 670px;
        width: 100%;
        background-image: url("../../../public/images/contact-bg.png");
        background-color: $themeColor;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .meta {}
    }
  }
}