// Side Bar
.sidebar-s {
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  height: 100%;
  inset: 0;
  overflow: hidden;

  .side-block {
    // width: 100%;
    width: 280px;
    background-color: #ffffff;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.9, 0.32, 0.46, 1.18) 0s;

    &::-webkit-scrollbar {
      width: 5px;
    }

    @include media(">=phone", "<=480px") {
      width: 70%;
    }

    &.show {
      left: 0;

      .body {
        overflow-y: hidden;
      }
    }

    &.hide {
      left: -450px;
    }

    .hdr {
      padding: 24px 14px;
      width: 100%;

      .hdr-tag {
        .logo {
          height: 40px;
        }
      }

      .icon-close {
        height: 20px;
        cursor: pointer;

        svg {
          height: 18px;
          width: 18px;

          path {}
        }
      }
    }

    .menu-items {
      padding: 20px 14px;

      .item {
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 8px 0;
        border-radius: 4px;

        .li {
          width: 100%;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          margin-right: 30px;
          align-items: start;
          @include anim;

          @include media("<=desktop") {
            font-size: 15px;
            text-align: start;
          }

          @include media("<=tablet") {
            display: flex;
          }
        }

        &.active {
          background: #ffffff;

          .li {
            color: #5c5c5c;
          }
        }
      }
    }
  }
}