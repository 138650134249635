// Header
.header-camp {
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  padding: 12px 0px;
  position: absolute;

  // border-bottom: 0.5px solid #00000033;
  .wrap {
    .left {
      flex: 0.4;

      .logo-img {
        z-index: 10;

        .logo {
          height: 58px;
        }
      }
    }

    .right {
      flex: 1;

      .menu-list {
        .menu-item {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          color: #fff;
          cursor: pointer;
          @include anim;

          &:hover {
            color: $themeColor;
          }
        }
      }
    }
  }
}