*,
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
label,
fieldset,
input,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul,
li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

div,
img,
button,
input,
textarea,
select {
  outline: none;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a,
img {
  border: none;
  outline: none;
  text-decoration: none;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wordwrap {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99%;
}

@mixin wordwrap() {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99%;
}

.pointer,
button {
  cursor: pointer;
}

.cleanbtn {
  border: none;
  outline: none;
  background: none;
}

a,
.noul {
  text-decoration: none;
  outline: none;
  border: none;
}

.nouls,
.noulh:hover {
  text-decoration: underline;
}

.nous {
  user-select: none;
}

.anim {
  transition: all 0.3s linear 0s;
}

@mixin anim($duration: 0.3s) {
  transition: all $duration linear 0s;
}

.button {
  background: $themeColor;
  border: 1px $themeColor solid;
  color: #fff;
  display: table;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.cancel {
    background: $lightButtonColor;
    border: 1px $lightButtonBorderColor solid;
    color: $themeColor;

    &:hover {
      background: $lightButtonColorHover;
    }
  }
}

.show {
  opacity: 1;
  visibility: visible;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.input {
  border: 1px #c3c3c3 solid;
  padding: 8px 12px;
  line-height: 1;
  border-radius: 5px;
}

.cover {
  z-index: 101;
  background: rgba(255, 255, 255, 0.95);
}

.holder {
  background: #ddd !important;
  animation: placeholder 2s linear infinite;
  border-radius: 5px;
  opacity: 1;
}

@keyframes placeholder {
  50% {
    opacity: 0.4;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-button {
  /* 2 */
}

::-webkit-scrollbar-track {}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.8);
  // border-radius: 15px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

// Modal
.ReactModal__Content {
  position: absolute;
  inset: 50% auto auto 50%;
  border-radius: 8px;
  overflow: auto;
  outline: none;
  transform: translate(-50%, -50%);
  // background-color: #fff;
  // max-width: 557px;
  // max-height: calc(100vh - 40px);
  height: auto;
  width: auto;
  opacity: 1;

  &::-webkit-scrollbar {
    width: 4px;
  }
}

.ReactModal__Overlay {
  inset: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.24);
  // background-color: rgba(69, 69, 69, 0.9);
  z-index: 999999;
}