/* Swiper styles */
.slide {
  @apply relative;
}
.slide-img {
  @apply absolute inset-0 bg-no-repeat bg-cover;
}
.swiper {
  width: 100%;
  height: 70vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination {
}
.swiper-pagination {
}
.swiper-pagination-bullet {
  background: transparent !important;
  height: 14px !important;
  width: 14px !important;
  border: 2px solid #ffffff;
  /* opacity: 0.2 !important; */
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ffefc3 !important;
}
